import { Scenario, soundTypes } from '../../types/Scenario';

const ocean: Scenario = {
  id: 1,
  name: 'Rainstorm',
  slug: 'rainstorm',
  description: 'Calming rainstorm',
  sounds: {
    waves1: {
      id: 0,
      slug: 'waves1',
      name: 'Waves 1',
      type: soundTypes.background,
      paths: ['ocean-waves-1.mp3'],
      volume: 0.75,
      frequency: 0,
      area: [
        [-0.25, 0, 0],
        [-0.25, 0, 0],
      ],
      mute: false,
    },
    waves2: {
      id: 1,
      slug: 'waves2',
      name: 'Waves 2',
      type: soundTypes.background,
      paths: ['ocean-waves-2.mp3'],
      volume: 0.75,
      frequency: 0,
      area: [
        [0.25, 0, 0],
        [0.25, 0, 0],
      ],
      mute: false,
    },
    seagulls: {
      id: 2,
      slug: 'seagulls',
      name: 'Seagulls',
      type: soundTypes.random,
      paths: [
        'seagull/seagull-1.mp3',
        'seagull/seagull-2.mp3',
        'seagull/seagull-3.mp3',
        'seagull/seagull-4.mp3',
        'seagull/seagull-5.mp3',
        'seagull/seagull-6.mp3',
      ],
      volume: 0.5,
      frequency: 0.5,
      area: [
        [-2, 2, -2],
        [2, 2, 2],
      ],
      mute: false,
    },
    waterDrops: {
      id: 3,
      slug: 'waterDrops',
      name: 'Water Drops',
      type: soundTypes.random,
      paths: [
        'water-drops/water-drops-1.mp3',
        'water-drops/water-drops-2.mp3',
        'water-drops/water-drops-3.mp3',
        'water-drops/water-drops-4.mp3',
        'water-drops/water-drops-5.mp3',
        'water-drops/water-drops-6.mp3',
      ],
      volume: 0.3,
      frequency: 0.75,
      area: [
        [-2, -2, -2],
        [2, 0, 2],
      ],
      mute: false,
    },
    waterSplash: {
      id: 4,
      slug: 'waterSplash',
      name: 'Water Splash',
      type: soundTypes.random,
      paths: ['water-splash/water-splash.mp3'],
      volume: 0.2,
      frequency: 0.25,
      area: [
        [-2, -2, -2],
        [2, 0, 2],
      ],
      mute: false,
    },
    sailFlapping: {
      id: 5,
      slug: 'sailFlapping',
      name: 'Sail Flapping',
      type: soundTypes.random,
      paths: [
        'sail-flapping/sail-flapping-1.mp3',
        'sail-flapping/sail-flapping-2.mp3',
        'sail-flapping/sail-flapping-3.mp3',
        'sail-flapping/sail-flapping-4.mp3',
        'sail-flapping/sail-flapping-5.mp3',
      ],
      volume: 0.2,
      frequency: 0.5,
      area: [
        [-1, 1, -1],
        [2, 2, 2],
      ],
      mute: false,
    },
  },
};

export default ocean;
