import { Scenario, soundTypes } from '../../types/Scenario';

const city: Scenario = {
  id: 1,
  name: 'City',
  slug: 'city',
  description: 'City Traffic & Construction',
  sounds: {
    cityRumbling: {
      id: 0,
      slug: 'cityRumbling',
      name: 'City Rumbling',
      type: soundTypes.background,
      paths: ['city-rumbling.mp3'],
      volume: 0.75,
      frequency: 0,
      area: [
        [-0.5, 0, 0],
        [-0.5, 0, 0],
      ],
      mute: false,
    },
    cityTraffic: {
      id: 1,
      slug: 'cityTraffic',
      name: 'City Traffic',
      type: soundTypes.background,
      paths: ['city-traffic.mp3'],
      volume: 0.5,
      frequency: 0,
      area: [
        [0.5, 0, 0],
        [0.5, 0, 0],
      ],
      mute: false,
    },
    construction: {
      id: 2,
      slug: 'construction',
      name: 'Construction',
      type: soundTypes.random,
      paths: ['construction/construction-1.mp3', 'construction/construction-2.mp3'],
      volume: 0.75,
      frequency: 0.2,
      area: [
        [-2, 1, -2],
        [2, 2, 2],
      ],
      mute: false,
    },
    cyclist: {
      id: 3,
      slug: 'cyclist',
      name: 'Cyclist',
      type: soundTypes.random,
      paths: ['cyclist/cyclist-passing.mp3', 'cyclist/bike-bell-1.mp3', 'cyclist/bike-bell-2.mp3'],
      volume: 0.75,
      frequency: 0.25,
      area: [
        [-2, 1, -2],
        [2, 2, 2],
      ],
      mute: false,
    },
    sirens: {
      id: 4,
      slug: 'sirens',
      name: 'Sirens',
      type: soundTypes.random,
      paths: [
        'traffic/ambulance-siren.mp3',
        'traffic/fire-truck-siren.mp3',
        'traffic/garbage-truck.mp3',
        'traffic/police-siren.mp3',
        'traffic/siren.mp3',
      ],
      volume: 0.75,
      frequency: 0.5,
      area: [
        [-2, 1, -2],
        [2, 2, 2],
      ],
      mute: false,
    },
    cars: {
      id: 5,
      slug: 'cars',
      name: 'Cars',
      type: soundTypes.random,
      paths: [
        'traffic/car-alarm.mp3',
        'traffic/car-horn-1.mp3',
        'traffic/car-horn-2.mp3',
        'traffic/car-start.mp3',
        'traffic/passing-car-1.mp3',
        'traffic/passing-car-2.mp3',
        'traffic/passing-car-3.mp3',
      ],
      volume: 0.75,
      frequency: 0.5,
      area: [
        [-2, 1, -2],
        [2, 2, 2],
      ],
      mute: false,
    },
    heavyVehicles: {
      id: 6,
      slug: 'heavyVehicles',
      name: 'Heavy Vehicles',
      type: soundTypes.random,
      paths: ['traffic/backing-up.mp3', 'traffic/bus-pass.mp3', 'traffic/garbage-truck.mp3'],
      volume: 0.75,
      frequency: 0.5,
      area: [
        [-2, 1, -2],
        [2, 2, 2],
      ],
      mute: false,
    },
    motorcycles: {
      id: 7,
      slug: 'motorcycles',
      name: 'Motorcycles',
      type: soundTypes.random,
      paths: [
        'traffic/moped-1.mp3',
        'traffic/moped-2.mp3',
        'traffic/moped-pass.mp3',
        'traffic/passing-motorbike-1.mp3',
        'traffic/passing-motorbike-2.mp3',
        'traffic/passing-motorbike-3.mp3',
      ],
      volume: 0.75,
      frequency: 0.5,
      area: [
        [-2, 1, -2],
        [2, 2, 2],
      ],
      mute: false,
    },
    policeRadio: {
      id: 8,
      slug: 'policeRadio',
      name: 'Police Radio',
      type: soundTypes.random,
      paths: [
        'traffic/police-radio-1.mp3',
        'traffic/police-radio-2.mp3',
        'traffic/police-radio-3.mp3',
      ],
      volume: 0.75,
      frequency: 0.2,
      area: [
        [-2, 1, -2],
        [2, 2, 2],
      ],
      mute: false,
    },
  },
};

export default city;
